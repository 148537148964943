<template>
  <cta-banner :banner="banner" />
</template>
<script>
import CtaBanner from "./CtaBanner";
import {banners, NAMES} from "./banners"

export default {
  name: "Banner001MasAnadido",
  components: {
    CtaBanner,
  },
 created () {
    this.banner = banners[NAMES.MAS_ANADIDO]
 }
};
</script>
