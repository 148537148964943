<template>
  <div class="pad-t-2" ref="banner">
    <div class="bb-container bb-container--lista">
      <div
          :class="'ml-news ml-news--' + banner.color"
          @click="goToConstructor"
      >
        <div class="ml-news__picture">
          <div class="ml-news__label">
            <span :class="'badge badge--sm badge--' + banner.color1" v-text="banner.tag"/>
          </div>
          <img
              :src="'/covers/' + banner.cover1"
              alt=""
              loading="eager"
          />
        </div>

        <div class="ml-news__content" v-text="banner.title"/>
        <div class="ml-news__actions">
          <button class="button button--sm button--dark">Explorar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CtaBanner",
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToConstructor()
    {
      window.mixpanel.track("Click banner llista", {
        "Name": this.banner.title,
      });
      this.$router.push({ name: 'list-constructor', hash: '#constructor_sections' });
    },
    observeBanner() {
      const observer = new IntersectionObserver((entries) => {
        entries.map((entry) => {
          if (entry.isIntersecting) {
            this.$store.commit("SET_CTA_FIXED_VISIBILITY2", true);
          } else {
            this.$store.commit("SET_CTA_FIXED_VISIBILITY2", false);
          }
        });
      });
      let options = {
        // root: document.querySelector('#scrollArea'),
        root: null, // Null = Viewport del navegador
        // rootMargin: '0px',
        threshold: 0,
      };
      observer.observe(this.$refs.banner, options);
    }
  },
  mounted() {
    this.observeBanner()
  }
}
</script>